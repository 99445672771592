import React from 'react';
import { useLocation } from 'react-router';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';

import { CenterProducts, DisplayStyle } from '../../../../../../types/centerArticleType';
import S from '../styles';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';

export const ProductPairBlock = ({ products }: { products: CenterProducts[] }) => {
  const datalayer = useGTMDataLayer();
  const location = useLocation();
  return (
    <S.ProductPairCol s="12" m="12" l="12" xl="12">
      {products.map((product, index) => (
        <S.ProductPairBlock key={product.name} $index={index}>
          <S.Content>
            <Text variant={TextVariant.Header2}>{product.name}</Text>
            {((product.memberPrice && product.memberPrice.isCampaign) ||
              (product.nonMemberPrice && product.nonMemberPrice.isCampaign)) && (
              <S.StyledLabel variant="moss" text="Kampanje" />
            )}
            {product.memberPrice && (
              <S.TextWrapper>
                <S.InlineText variant={TextVariant.Header3}>
                  Medlemspris: {product.memberPrice.campaignPrice || product.memberPrice.price},-
                </S.InlineText>
                {product.memberPrice.campaignPrice && (
                  <S.InlineText
                    variant={TextVariant.SubHeader}
                  >{` (Før: ${product.memberPrice.price},-)`}</S.InlineText>
                )}
              </S.TextWrapper>
            )}
            {product.nonMemberPrice && (
              <S.TextWrapper>
                <S.InlineText variant={TextVariant.SubHeader}>
                  Ikke medlem: {product.nonMemberPrice.campaignPrice || product.nonMemberPrice.price},-
                </S.InlineText>
                {product.nonMemberPrice.campaignPrice && (
                  <S.InlineText variant={TextVariant.SubHeader}>
                    {` (Før: ${product.nonMemberPrice.price},-)`}
                  </S.InlineText>
                )}
              </S.TextWrapper>
            )}
            {product.booking && product.booking.data.href && product.booking.type === 'externalLink' && (
              <Button
                href={product.booking.data.href}
                onClick={() =>
                  product?.booking?.data.href &&
                  datalayer?.push({
                    event: 'cta_click',
                    cta_text: 'Finn ledig tid',
                    cta_location: location.pathname,
                    cta_url: product.booking.data.href,
                    cta_hostname: new URL(product.booking.data.href).hostname,
                    cta_position: 'content',
                  })
                }
                variant={product.displayStyle === DisplayStyle.WithPricePrimaryButton ? 'signature' : 'secondary'}
                target={product.booking.data.blank ? '_blank' : '_self'}
              >
                Finn ledig tid
              </Button>
            )}
            {product.ingress && <S.Ingress>{product.ingress}</S.Ingress>}
          </S.Content>
        </S.ProductPairBlock>
      ))}
    </S.ProductPairCol>
  );
};
